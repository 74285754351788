/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
import { useNavigate, Link } from 'react-router-dom';
import Modal from 'react-modal';

const Step1 = ({ onNext, cid, role,token,formData, setFormData }) => {

    const [stateList, setStateList] = useState([]);
    const [lataList, setLataList] = useState([]);
    const [rateCenterList, setRateCenterList] = useState([]);
    const [searchInstrunction, setSearchInstrunction] = useState(false);
    const [orderInstrunction, setOrdernstrunction] = useState(false);
    const [stateInstrunction, setStateInstrunction] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [methodType, setMethodType] = useState(0);
    const [searchType, setSearchType] = useState(1);
    const [searchAction, setSearchAction] = useState(0);
    const [file, setFile] = useState(null);
    const [searchData , setSearchData] = useState({
        state: "",
        lata: "",
        rate_center: '',
        sequential: '',
        tnMask: '',
        npa:"",
        nxx:"",
        rateCenterTier: '',
        qty: 0
    });
    const [stateId, setStateId] = useState([]);
    const [selectedStateId, setSelectedStateId] = useState(null);
    const [lataId, setLataId] = useState('');
    const [showValidationError, setShowValidationError] = useState(false);
    const [searchQty, setSearchQty] = useState(0);
    const [searchNumberList, setSearchNumberList] = useState([]);
    const [reserveNumberList, setReserveNumberList] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [companyList, setcompanyList] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [selectedValue, setSelectedValue] = useState('');
    const [showCustomerValidationError, setShowCustomerValidationError] = useState(false);
    const [showSearchNumber, setShowSearchNumber] = useState(false);
    const initialState = {
        state: '',
        lata: '',
        rate_center: '',
        tnMask: '',
        npa:"",
        nxx:"",
        qty: 0,        
        sequential: false,
        lataList: [],
        rateCenterList: [],
        toll_free_type: "",
        toll_free_value: '',
        result:0
      };
    
      const [rows, setRows] = useState([{ ...initialState }]);
    //   const [searchQty, setSearchQty] = useState(0);
//     const [apiCallCount, setApiCallCount] = useState(0); // Track the total number of API calls made
//   const [apiResponseCount, setApiResponseCount] = useState(0);
   
    useEffect(() => {
        if(formData.reserveNumberList){
            console.log(formData.reserveNumberList);
            setReserveNumberList(formData.reserveNumberList);
        }
        if(formData.selectedCompany){
            console.log(formData);
            setSelectedValue(formData.selectedCompany);
        }
        if(role > 2){
            setSelectedValue(cid);
        }
        console.log(role);
        
        // Make the API call when the component mounts
        getStateList();
        // getLataStateRateCenter();
        if(token){
            getCustomerNameList();
        }
    }, [token]);

   
   

    useEffect(() => {
        if(stateId != null && stateId){
            console.log(stateId);
            // getStateLataList();
            // getStateLataRateCenterList();
        }else{
            setLataList([]);
            setRateCenterList([]);
            
        }
        setSearchData((prevData) => ({
            ...prevData,
            ['lata']: '',
            ['rate_center']: ''
        }));
    
    },[stateId]);

    useEffect(() => {
        if( lataId){
            console.log(lataId);
            // getStateLataRateCenterList();
            setSearchData((prevData) => ({
                ...prevData,
               
                ['rate_center']: ''
            }));
        }
        if(lataId == null){
            console.log(lataId);
            // getStateLataRateCenterList();
        }
    
    },[lataId]);

    
    const getStateList = async () => {
        try {
            // setApiCallCount((prevCount) => prevCount + 1);
            // setLoading(true);
            const response = await axios.get(`${baseUrl}/state`);
            const allArray = response.data;
            // setLoading(false); 
            setStateList(allArray.list);
            // setApiResponseCount((prevCount) => prevCount + 1);
            // console.log(response);
        } catch (error) {
            // setLoading(false);
            // setLoading(false);
           console.log(error);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }  
    };

    const getStateLataList = async (id) => {
        try {
            
            // setApiCallCount((prevCount) => prevCount + 1);
            // setLoading(true);
            const response = await axios.get(`${baseUrl}/state_lata/${id}`); //
            const allArray = response.data;
            setLataList(allArray.list);
            // setLoading(false); 
            // console.log(allArray);
            return allArray.list;
            // setApiResponseCount((prevCount) => prevCount + 1);
          
        } catch (error) {
            // setLoading(false);
            // setLoading(false);
           console.log(error);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }  
    };

    const getStateLataRateCenterList = async (index, state, lata) => {
        try {
           
            
            console.log(state);
            const response = await axios.post(`${baseUrl}/state_lata_rate_center`, {state_id:state, lata_id:lata});
            const allArray = response.data;
            setRateCenterList(allArray.list);
            // setLoading(false); 
            // console.log(allArray);
            return allArray.list;
            // setApiResponseCount((prevCount) => prevCount + 1);
          
        } catch (error) {
            // setLoading(false);
            // setLoading(false);
           console.log(error);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }  
    };
    
   

    const getCustomerNameList = async () => {
        try {
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/active-user-company`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            const allArray = response.data.filter(item => item.id !== 1);
            console.log(response.data.filter(item => item.id !== 1));
            // setApiResponseCount((prevCount) => prevCount + 1);
            setcompanyList(allArray);
           
           
            
            
          
        } catch (error) {
            // setLoading(false);
            
           console.log(error);
            
        }
    };

    

    const handleChange = (e) => {
        setFormData({ ...formData, step1Value: e.target.value });
    };
    
    const handleStateId = async (e, rowIndex) => {

        const selectedIndex = e.target.selectedIndex;
        const selectedOption = e.target.options[selectedIndex];
        const dataId = selectedOption.getAttribute('data-id');
        
        // setStateId(dataId);
          setStateId((prevArray) => Array.isArray(prevArray) ? [...prevArray.slice(0, rowIndex), dataId, ...prevArray.slice(rowIndex + 1)] : prevArray);
        try {
            const lataOptions =  await getStateLataList(dataId);
            
            const rateCenterList = await getStateLataRateCenterList(rowIndex, dataId);
            // console.log(rateCenterList);
            setRows((prevRows) => {
              const updatedRows = [...prevRows];
              updatedRows[rowIndex] = {
                ...updatedRows[rowIndex],
                lataList: lataOptions,
                rateCenterList: rateCenterList
              };
            //   console.log(updatedRows);
              return updatedRows;
            });

            
            
          } catch (error) {
            console.error('Error fetching lata options:', error);
          }
      
      
        
    }
    
    const handleLataId = async(e, rowIndex) => {

        const selectedIndex = e.target.selectedIndex;
        const selectedOption = e.target.options[selectedIndex];
        const dataId = selectedOption.getAttribute('data-id');
        // setLataId(dataId);
       
        const rateCenterList = await getStateLataRateCenterList(rowIndex, stateId[rowIndex], dataId);
            // console.log(rateCenterList);
            setRows((prevRows) => {
                const updatedRows = [...prevRows];
                updatedRows[rowIndex] = {
                  ...updatedRows[rowIndex],
                  rateCenterList: rateCenterList
                };
                return updatedRows;
              });
    }
    // const handleInputChange = (e) => {
    //     const { name, value, type, checked,  } = e.target;
      
    //     const inputValue = type === 'checkbox' ? checked : value;
        
    //     setSearchData((prevData) => ({
    //             ...prevData,
    //             [name]: inputValue
    //         }));
    
    //     // setValidationErrors((prevErrors) => ({
    //     //   ...prevErrors,
    //     //   [name]: false
    //     // }));
    // };

    const validateTollFreeValue = (type, value) => {
        if (type === "tollFreeWildCardPattern") {
            return /^8[\d*][\d*]$/.test(value) && value.length === 3;
        } else if (type === "tollFreeVanity") {
            return /^[a-zA-Z0-9]+$/.test(value) && value.length >= 7;
        }
        return false; // Invalid type
    };
    const handleSearch = async(e) => {
        e.preventDefault();
        setFormData({ ...formData, searchType: searchType, methodType: methodType});
        const errors = rows.reduce((acc, obj, index) => {
            if (obj.qty === undefined || !obj.qty || parseInt(obj.qty, 10) <= 0) {
              acc.push(`\nRow ${index + 1}: Please enter a valid quantity`);
            }
            return acc;
          }, []);
          if (errors.length > 0) {
            alert(errors);
            return;
          }
          const dataErrors = [];
          console.log(rows);
        rows.forEach((obj, index) => {
            if(obj.toll_free_type == "tollFreeWildCardPattern" && obj.toll_free_type == "tollFreeVanity"){
                const isValid = validateTollFreeValue(obj.toll_free_type, obj.toll_free_value);
                    if (!isValid) {
                        dataErrors.push(`\n Row ${index + 1}: Value "${obj.toll_free_value}" for type "${obj.toll_free_type}" is invalid.`);
                    }
            }
            
        });
        if(dataErrors.length > 0){
            alert(dataErrors);
            return;
        }
        
        try {
            setButtonDisabled(true);
            setPopupOpen(true);
        const response = await axios.post(`${baseUrl}/get-search-multiple-row-tnnumber`, {rows:rows, searchType:searchType, methodType:methodType});
            const allArray = response.data;
            console.log(allArray);
            console.log(rows);
            
            const updatedRows = rows.map((row,index) => ({ ...row, result: allArray[1]?.length || row.result }));

            // let resultData;
            // if(searchType === 0){
            //      resultData = allArray.map(item => ({result: item.size ? item.size : 0 }));
            // }else{
                
            //         resultData = allArray.map(item => ({result: item.resultCount ? item.resultCount : 0 }));
                
                
                
            // }
            
            
            // const mergedRows = rows.map((item, index) => ({ ...item, ...resultData[index] }));
            // console.log(mergedRows);
            setRows(updatedRows);
            
            
           

            setButtonDisabled(false);
            setPopupOpen(false);
            
            if(allArray.length > 0 ){
                let numbers;
                // if(searchType === 0){
                //     numbers = allArray.reduce((acc, item) => (item.tnResult ? acc.concat(item.tnResult) : acc), []).filter(Boolean);
                // }else{
                    // if(methodType === 0){
                    //     numbers = allArray.reduce((acc, item) => {
                    //         if (item.telephoneNumberDetailList && item.telephoneNumberDetailList.telephoneNumberDetail) {
                    //           const details = item.telephoneNumberDetailList.telephoneNumberDetail;
                          
                    //           const modifiedNumbers = Array.isArray(details)
                    //             ? details.map(detail => ({
                    //                 ...detail,
                    //                 rateCenterName: detail.rateCenter,
                    //                 province: detail.state,
                    //                 telephoneNumber: detail.fullNumber,
                    //                 rateCenterTier: detail.tier
                    //               }))
                    //             : [{
                    //                 ...details,
                    //                 rateCenterName: details.rateCenter,
                    //                 province: details.state,
                    //                 telephoneNumber: details.fullNumber,
                    //                 rateCenterTier: details.tier
                    //               }];
                          
                    //           return acc.concat(modifiedNumbers);
                    //         } else {
                    //           return acc;
                    //         }
                    //       }, []).filter(Boolean);
                          
                    // }
                    if(methodType === 0){
                        console.log("method type = 0");
                        numbers = allArray.reduce((acc, obj) => acc.concat(...Object.values(obj)), []);
                    }
                    if(methodType === 2){
                        console.log("method type = 2");
                        numbers = allArray.reduce((acc, obj) => acc.concat(...Object.values(obj)), []);
                    }
                    
                   

                // }
                console.log(allArray);
                console.log(numbers);
                // setSearchNumberList(numbers);
                setSearchNumberList(numbers);
                
                // setSearchQty(allArray.tnResult.length);
            }else{
                setSearchNumberList([]);
            }
            setShowSearchNumber(true);
            
        } catch (error) {
            // setLoading(false);
           console.log(error);
           setPopupOpen(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
           
    };

    const handleClearSearch = async(e) => {
        e.preventDefault();
        setSearchData({state: "",
        lata: "",
        rate_center: '',
        sequential: '',
        tnMask: '',
        npa:"",
        nxx:"",
        rateCenterTier: '',
        qty: 0});
        setRows([{ ...initialState }]);
    };

    const handleCheckboxChange = (event, item) => {
        const isChecked = event.target.checked;

        // if (isChecked) {
        //     setSelectedItems(prevSelected => [...prevSelected, item.telephoneNumber]);
        // } else {
        //     setSelectedItems(prevSelected =>
        //         prevSelected.filter(phoneNumber => phoneNumber !== item.telephoneNumber)
        //     );
        // }
        if (isChecked) {
            setSelectedItems(prevSelected => [...prevSelected, { tn: item.telephoneNumber }]);
        } else {
            setSelectedItems(prevSelected =>
                prevSelected.filter(tnObj => tnObj.tn !== item.telephoneNumber)
            );
        }
    };

    const handleSelectAll = async(e) => {
        e.preventDefault();
        const allPhoneNumbers = searchNumberList.map(item => ({ tn: item.telephoneNumber }));
        // console.log(allPhoneNumbers);
        // setSelectedItems(allPhoneNumbers);
        try {
            
            // setReserveNumberList(searchNumberList);
            // setFormData({ ...formData, reserveNumberList: searchNumberList });
            if (formData.reserveNumberList) {
                
            
                const mergedNumbers = [...formData.reserveNumberList, ...searchNumberList];
                const uniqueArray = [...mergedNumbers.reduce((map, item) => map.set(item.telephoneNumber, item), new Map()).values()];
                
                setSelectedItems(uniqueArray);
                setReserveNumberList(uniqueArray);
                setFormData({ ...formData, reserveNumberList: uniqueArray });
              } else {
                // If formData.reserveNumberList doesn't exist, set allPhoneNumbers
                setSelectedItems(allPhoneNumbers);
                setReserveNumberList(searchNumberList);
                setFormData({ ...formData, reserveNumberList: searchNumberList });
              }
               
            } catch (error) {
                // setLoading(false);
               console.log(error);
                // if (error.response) {
                //     // setError("Unauthorized");
                //     navigate("/");  
                //     window.location.reload();
                // }
            }
    };

    const handleClearAll = async (e) => {
        e.preventDefault();
        setSelectedItems([]); 
    }

    const handleAddSelected = async(e) => {
        e.preventDefault();
        if (selectedItems.length === 0) {
            alert('Please select at least one item to add.');
            return;
        }
        
        

        // Perform reservation API call using selectedItems array

        try {

            const matchingObjectsArray = [];
            selectedItems.forEach(selectedItem => {
                const selectedPhoneNumber = selectedItem.tn;
                const matchingObject = searchNumberList.find(item => item.telephoneNumber === selectedPhoneNumber);
                  
                if (matchingObject) {
                    matchingObjectsArray.push(matchingObject);
                }
            
            });

            if (formData.reserveNumberList) {
                
                const mergedNumbers = [...formData.reserveNumberList, ...matchingObjectsArray];
                const uniqueArray = [...mergedNumbers.reduce((map, item) => map.set(item.telephoneNumber, item), new Map()).values()];
                
                // setSelectedItems(uniqueArray);
                setReserveNumberList(uniqueArray);
                setFormData({ ...formData, reserveNumberList: uniqueArray });
            }else{
                setReserveNumberList(matchingObjectsArray);
                setFormData({ ...formData, reserveNumberList: matchingObjectsArray });
            }
            
                
            } catch (error) {
                // setLoading(false);
               console.log(error);
                // if (error.response) {
                //     // setError("Unauthorized");
                //     navigate("/");  
                //     window.location.reload();
                // }
            }
    };

    const toggleSearchVisible = () => {
        setSearchInstrunction(!searchInstrunction);
    };

    const toggleOrderVisible = () => {
        setOrdernstrunction(!orderInstrunction);
    };

    const toggleStateInstruction = () => {
        setStateInstrunction(!stateInstrunction);
    };

    const handleRemoveAll = async(e) => {
        e.preventDefault();
        setSelectedItems([]);
        setReserveNumberList([]);
        setFormData({ ...formData, reserveNumberList: [], routePath: [], tnNumberRouteList: [] });
        
    };

    const handleRemoveSelected = async(e, item) => {
        e.preventDefault();

        const updatedRoutePath = removeItemByTelephoneNumber(formData, item.telephoneNumber);
        const updateTnRoutePath = removeTnRoutePathNumber(formData, item.telephoneNumber);
        setSelectedItems(prevSelected =>
            prevSelected.filter(tnObj => tnObj.tn !== item.telephoneNumber)
        );
        const updatedList = reserveNumberList.filter(tnObj => tnObj.telephoneNumber !== item.telephoneNumber);
        // console.log(updatedList);
        setReserveNumberList(updatedList);
        const updateData = { ...formData, reserveNumberList: updatedList, routePath: updatedRoutePath, tnNumberRouteList : updateTnRoutePath };
        setFormData(updateData);
        
        // setSelectedItems([]);
        // setReserveNumberList([]);
    };

    const removeItemByTelephoneNumber = (formData, telephoneNumber) => {
        if(formData.routePath){
            return formData.routePath.filter(obj => {
                const key = Object.keys(obj)[0]; // Extract the key (telephone number)
                return key !== telephoneNumber; // Keep objects where the key does not match the given telephone number
            });
        }else{
            return [];
        }   
    };
    const removeTnRoutePathNumber = (formData, telephoneNumber) => {
        if(formData.tnNumberRouteList){
            return formData.tnNumberRouteList.filter(obj => obj.telephoneNumber !== telephoneNumber);
        }else{
            return [];
        }
    }
    const handleCompanyChange = (e) => {
        setSelectedValue(e.target.value);        
        setFormData({ ...formData, selectedCompany: e.target.value });
    };

    const handleNext = (e) => {
        e.preventDefault();
        let hasCustomerValidationError = false;
        let hasValidationError = false;
        if( role <= 2 && selectedValue === ''){
            setShowCustomerValidationError(true);
            hasCustomerValidationError = true;
        }
        console.log(selectedValue);
        console.log(companyList);
        const selectedCompany = companyList.find(comp => comp.id == selectedValue);
        if(!selectedCompany?.short_name){
            alert("Please enter company short name");
        }
        if (reserveNumberList.length === 0) {
          setShowValidationError(true);
          hasValidationError = true;
        } 
        if (!hasCustomerValidationError && !hasValidationError && selectedCompany.short_name) {
            onNext();
        }
    };

    const handleRadioChange = (e) => {
        setMethodType(Number(e.target.value));
        setRows([{ ...initialState }]);
    };

    const handleSearchActionChange = (e) => {
        setSearchAction(Number(e.target.value));        
    };

    const handleSearchRadioChange = (e) => {
        setSearchType(Number(e.target.value));
        
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleFileCheck = async(e) => {
        e.preventDefault();
        if(file){
            try {
                setButtonDisabled(true);
                setPopupOpen(true);
                setFormData({ ...formData, searchType: searchType, methodType: methodType});
            const newData = new FormData();
            newData.append("file", file); 
            newData.append("methodType", methodType);                                    
            const response = await axios.post(`${baseUrl}/validate-search-tnnumber`, newData,{
                headers: {
                     Authorization: `Bearer ${token}`,
                     "Content-Type": "multipart/form-data",
                }
            });
            console.log(response.data.data);
            setSearchNumberList(response.data.data);
            setShowSearchNumber(true);
            setButtonDisabled(false);
            setPopupOpen(false);
        } catch (error) {
            // setLoading(false);
           console.log(error);
           setPopupOpen(false);
        }
        }else{
            alert('Please upload Attachment with pre defined search criteria.');
        }
        
    }

    const handleInputChange = (e, rowIndex) => {
        const { name, value, type, checked } = e.target;
        const newRows = [...rows];
        newRows[rowIndex] = {
          ...newRows[rowIndex],
          [name]: type === 'checkbox' ? checked : value,
        };
        setRows(newRows);
      };

    const handleAddRow = () => {
        setRows([...rows, { ...initialState }]);
    };

    const handleDeleteRow = (e, rowIndex) => {
        e.preventDefault();      
        const newData = rows.filter((_, index) => index !== rowIndex);
        setRows(newData);
    }

    return(
        
    <>
        {popupOpen && (
            <div className='overlay'><div className="custom-loader"></div></div> // Display the loader while loading is true
        ) }
        <div className="row">
            <div className="col-6">
                <div className="left-title">
                    <h1>Order New Number
                    <span><img src={`${process.env.PUBLIC_URL}/images/view.png`} onClick={toggleStateInstruction} style={{ cursor: 'pointer' }} /></span> </h1>                                      
                </div>                
            </div>            
            <div className="col-6">

            </div>            
        </div>
        {stateInstrunction && <div className="common-white-shadow-background setting-section">
            <div className="row">                
                <div className="introducebpx">
                    <p><strong>Instructions</strong></p>
                    <p>If you would like to limit the Lata and/or Rate Center for the numbers, please enter those values as well, otherwise leave them set to ##. <br />
                     If you would like the numbers returned to be only those which have no gaps between them, select "Sequential". <br />  Then select "Search" and the available numbers will be shown below.</p>
                </div>
            </div>
        </div>}
        {/* <div className="common-white-shadow-background setting-section">
            <div className="row">
                <h3>Search Type</h3>
                <div className="settingbox radio-standard-box">
                    <label htmlFor="Standard"><input type="radio" name="Search" id="Standard" />
                        Standard</label>
                    <label htmlFor="CityState"><input type="radio" name="Search" id="CityState" />
                        City/State</label>
                    <label htmlFor="Postal"><input type="radio" name="Search" id="Postal"/>
                        Postal Code</label>
                </div>
                <div className="introducebpx">
                    <p><strong>Instructions</strong></p>
                    <p>Search for Telephone Numbers by entering search criteria below or by uploading a file
                        containing multiple searches. The Telephone Number field supports "X", "x" or space
                        when the wildcard option is unchecked (Example: 312-xxx-1x1x). The Telephone Number
                        field supports 0-9, Aa-Zz, * or ? when the wildcard option is checked (Example:
                        "*Hello*" or "312?Hi*7").</p>
                </div>
            </div>
        </div> */}
        {role <= 2 && <div className="common-white-shadow-background setting-section">
            <div className="row">
                <h3 className='comapny-label'>Select Company</h3>
                <div className="inputbox">
                    <select name="" id=""  value={selectedValue} onChange={(e) => handleCompanyChange(e)}>
                        <option value="">Select Company</option>
                        {companyList.map((comp) => (                                                                    
                                <option key={comp.id} value={comp.id}>
                                    {comp.cname}
                                </option>  
                            ))}
                     
                    </select>
                </div>
            </div>
            {showCustomerValidationError && (
        <p className="error">Please select a value from the dropdown.</p>
      )}
        </div>}
        {/* <div className="common-white-shadow-background setting-section">
            <div className="row">
                <div className="settingbox radio-standard-box">
                    <label for="regular"><input type="radio" id="regular" name="method_type" value={0} checked={methodType === 0} onChange={handleRadioChange} />
                        Regular</label>          
                    {searchType ===0 && <label for="toll_free"><input type="radio" id="fileUpload" name="method_type" value={1} checked={methodType === 1} onChange={handleRadioChange} />
                        Toll Free</label>}                    
                </div>                
            </div>
        </div> */}
        {/*<div className="common-white-shadow-background setting-section">
            <div className="row"> 
                <div className="settingbox radio-standard-box">
                    <label htmlFor="inteliquent"><input type="radio" id="inteliquent" name="search_type" value={0} checked={searchType === 0} onChange={handleSearchRadioChange} />
                    Inteliquent</label>          
                    <label htmlFor="bandwidth"><input type="radio" id="bandwidth" name="search_type" value={1} checked={searchType === 1} onChange={handleSearchRadioChange} />
                    Bandwidth</label>
                    
                </div>
                    
            </div>
        </div>*/}
        <div className="common-white-shadow-background setting-section">
                <div className="row">
                    
                    
                    <div className="settingbox radio-standard-box">
                        <label htmlFor="pastNumber"><input type="radio" id="pastNumber" name="method_type" value={0} checked={methodType === 0} onChange={handleRadioChange} />
                            Did</label>          
                        {/* {searchType ===0 && <label htmlFor="fileUpload"><input type="radio" id="fileUpload" name="method_type" value={1} checked={methodType === 1} onChange={handleRadioChange} />
                            File Upload</label>} */}
                             {searchType !== 0 && <label for="toll_free"><input type="radio" id="toll_free" name="method_type" value={2} checked={methodType === 2} onChange={handleRadioChange} />
                            Toll Free</label>} 
                        
                    </div>
                    
                </div>
            </div>
            <div className="common-white-shadow-background setting-section">
                <div className="row">
                    
                    
                    <div className="settingbox radio-standard-box">
                        <label htmlFor="searchNumber"><input type="radio" id="searchNumber" name="search_action" value={0} checked={searchAction === 0} onChange={handleSearchActionChange} />
                            Search</label>          
                        {methodType != 2 && <label htmlFor="fileUpload"><input type="radio" id="fileUpload" name="search_action" value={1} checked={searchAction === 1} onChange={handleSearchActionChange} />
                            File Upload</label>} 
                            
                        
                    </div>
                    
                </div>
            </div>
            
        { searchAction !== 1 && 
            <div className="common-white-shadow-background setting-section tableinput-section first-number-table">
                {methodType === 0 && searchAction === 0 && (
                    <div className="row">
                        <table className="tablebox">
                            <thead>
                                <tr>
                                    <th>State<span>*</span></th>
                                    <th>Lata</th>
                                    <th>Rate Center</th>
                                    {/* <th>Tier</th> */}
                                    {/* <th>Search Tel. Number<br/>(input NPA or NPA-NXX)</th> */}
                                    <th>NPA</th>
                                    <th>NXX</th>
                                    <th>Qty<span>*</span></th>
                                    {/* <th>Wildcard</th> */}
                                    <th>Sequential</th>
                                    {/* <th>On-net Only</th> */}
                                    {/* <th>Local</th> */}
                                    {/* <th>Results</th> */}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((rowData, index) => (
                                    <tr key={index}>
                                      <td>
                                        <select
                                          name="state"
                                          value={rowData.state}
                                          onChange={(e) => {handleInputChange(e, index); handleStateId(e, index);  setSelectedStateId(e.target.selectedOptions[0].getAttribute('data-id'));}}
                                        >
                                         <option value="">##</option>
                                        {stateList.map((state) => (                                                                    
                                            <option key={state.id} data-id={state.id} value={state.code}>
                                                {state.code}
                                            </option>  
                                        ))}
                                        </select>
                                      </td>
                                      <td>
                                        <select
                                          name="lata"
                                          value={rowData.lata}
                                          onChange={(e) => {handleInputChange(e, index); handleLataId(e, index);}}
                                        >
                                          <option value="">##</option>
                                                            {rowData.lataList.map((value) => (                                                                    
                                                                <option key={value.id} data-id={value.id} value={value.lata}>
                                                                    {value.lata}
                                                                </option>  
                                                            ))}
                                        </select>
                                      </td>
                                      <td>
                                        <select
                                          name="rate_center"
                                          value={rowData.rate_center}
                                          onChange={(e) => handleInputChange(e, index)}
                                        >
                                          <option value="">##</option>
                                                            {rowData.rateCenterList.map((rate) => (                                                                    
                                                                <option key={rate.id} value={rate.rate_center} data-key={rate.id}>
                                                                    {rate.rate_center}
                                                                </option>  
                                                            ))}
                                        </select>
                                      </td>
                                      {/* <td>
                                        <input
                                          type="tel"
                                          name="tnMask"
                                          onChange={(e) => handleInputChange(e, index)}
                                          value={rowData.tnMask}
                                        />
                                      </td> */}
                                      <td>
                                        <input
                                          type="tel"
                                          name="npa"
                                          onChange={(e) => handleInputChange(e, index)}
                                          value={rowData.npa}
                                        /></td>
                                      <td><input
                                          type="tel"
                                          name="nxx"
                                          onChange={(e) => handleInputChange(e, index)}
                                          value={rowData.nxx}
                                        /></td>
                                      <td>
                                        <input
                                          type="number"
                                          name="qty"
                                          onChange={(e) => handleInputChange(e, index)}
                                          value={rowData.qty === 0 ? '' : rowData.qty}
                                          placeholder='Qty'
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="checkbox"
                                          name="sequential"
                                          onChange={(e) => handleInputChange(e, index)}
                                          checked={rowData.sequential}
                                        />
                                      </td>
                                      {/* <td>
                                        <p>{rowData.result}/{rowData.qty}</p>
                                      </td> */}
                                      <td>{index > 0 && <a className="search" onClick={(e) => handleDeleteRow(e, index)}  href='#'>Delete Row</a>}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>                
                    </div>
                )}
                {searchType === 1 && methodType === 2 && (                
                    <div className="row">
                        <table className="tablebox">
                            <thead>
                                {rows.map((rowData, index) => (
                                    <tr key={index}>
                                        <th>Search Criteria</th>
                                        <th>
                                            <select name="toll_free_type" value={rowData.toll_free_type} onChange={(e) => handleInputChange(e, index)}  >
                                                <option value="">Search type</option>
                                                <option selected value="tollFreeVanity">TollFree Vanity</option>
                                                <option value="tollFreeWildCardPattern">TollFree WildCard</option>
                                    
                                            </select>
                                        </th>
                                        {rowData.toll_free_type && <th>                                            
                                            <input type="tel" name="toll_free_value" placeholder='' onChange={(e) => handleInputChange(e, index)} value={rowData.toll_free_value}  />                                                
                                            <p style={{ color: "cornflowerblue" }}>
                                                {rowData.toll_free_type === "tollFreeVanity" && 
                                                    <span>
                                                        EXAMPLE: - NEWCARS <br/>
                                                        The Toll Free requested vanity number. Valid range is 7+ digits alphanumeric
                                                    </span>
                                                }
                                                {rowData.toll_free_type === "tollFreeWildCardPattern" && 
                                                    <span>
                                                        EXAMPLE: - 8**, 80*, 87* <br/>
                                                        The Toll Free requested wild card pattern. Valid range is 3 digits
                                                    </span>
                                                }
                                            </p>                                               
                                        </th> }
                                        {!rowData.toll_free_type && <th></th>}
                                        {!rowData.toll_free_type && <th></th>}
                                        
                                        {rowData.toll_free_type &&<th>
                                        <input type="number" name="qty" onChange={(e) => handleInputChange(e, index)} value={rowData.qty === 0 ? '' : rowData.qty} placeholder='Qty' />
                                        </th>}
                                    </tr>
                                ))}
                            </thead>
                        </table>
                    </div>
            
                )}
                <div className="row">
                    <div className="newrow-section">
                    {(<a className="newrow" href="#" onClick={handleAddRow}>+ Add Row</a>)}
                        <a className="search" onClick={handleClearSearch} href='#'>Clear</a>
                        <a className={buttonDisabled ? 'search disabled-link' : 'search'} onClick={handleSearch} href='#'>Search</a>
                        {/* <a className="delete" href="#">Delete All Rows</a> */}
                    </div>
                </div>    
            </div>
        }
        {methodType != 2 &&  searchAction === 1 && (
        <div className="common-white-shadow-background setting-section">
                <div className="row">
                    
                    <h3>Upload File (Using Template)</h3>
                    <div className="download-templte">
                        <a className="newrow" target="_blank" href={`${process.env.PUBLIC_URL}/template/TnSearch_Template.xls`}>Download Template</a>
                        <p>Submissions must be in the format of this template</p>
                    </div>
                    <div className="file-upload-field">
                        <div className="upload-btn-wrapper">
                              <input type="file" name="myfile" onChange={handleFileChange} placeholder="Select your local file based on our template" />
                            </div>
                    </div>
                    <div className="download-templte">
                        <a className={buttonDisabled ? 'search disabled-link' : 'search'} onClick={(e) => handleFileCheck(e)} href='#'>Upload File</a>
                    </div>
                    
                </div>
            </div>)}
            
        <div className="row">
            {showSearchNumber && <div className="col-6">
                <div className="common-white-shadow-background setting-section haft-whitebox">
                    <h3>Search Results 
                    <span><img src={`${process.env.PUBLIC_URL}/images/view.png`} onClick={toggleSearchVisible} style={{ cursor: 'pointer' }} /></span>
                        {/* <span onClick={toggleSearchVisible} style={{ cursor: 'pointer' }}>
                            {searchInstrunction ? '👁️' : '👁️'}
                        </span> */}
                    </h3>
                    {searchInstrunction && (
                        <>
                            <div className="introducebox">
                                <p><strong>Instructions</strong></p>
                                <p>Use the checkbox to add the telephone numbers from this table to the order.<br />  Once you are done selecting the numbers you would like to add to the order, select "Add Selected". <br />  If you would like to add all the numbers listed to the order, select "Add All".</p>
                            </div>
                            {/* <div className="introducebox">
                                <p><strong>Wireline Tier Information:</strong></p>
                                <p>0 - US48 On-Net<br />
                                    1- US48 On-Net Premium<br />
                                    2- US48 Off-Net<br />
                                    AK - Alaska<br />
                                    CAN - Canada<br />
                                    HI - Hawaii<br />
                                    PRI - Puerto Rico
                                </p>
                            </div>
                            <div className="introducebox">
                                <p><strong>Wireless Tier Information:</strong></p>
                                <p>WS-A-Wireless Tier A<br />
                                    WS-B - Wireless Tier B<br />
                                    WS-C - Wireless Tier C</p>
                            </div> */}
                        </>
                    )}
                    <div className="newrow-section">
                        <a className="newrow" href="#" onClick={handleSelectAll}>Add All</a>
                        <a className='search' href="#" onClick={handleClearAll}>Clear All</a>
                        <a className="delete" href="#" onClick={handleAddSelected}>Add Selected</a>
                    </div>
                    <table className="tablebox create-order-table">
                        <thead>
                            <tr>
                                 {methodType !== 2 && <th>Lata</th>}
                                 {methodType !== 2 &&<th>Rate Center</th>}
                                 {methodType !== 2 &&<th>State</th>}
                                <th>Tel Number</th>
                                {role <= 2 && <th>Carrier</th>}
                                {/* {methodType !== 2 &&<th>TIER</th>} */}
                                <th>Add To Queue</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchNumberList.length > 0 ? (searchNumberList.map((item, index) => 
                             <tr key={index}>
                             {methodType !== 2 &&<td>{item.lata}</td>}
                             {methodType !== 2 &&<td>{item.rateCenterName}</td>}
                             {methodType !== 2 &&<td>{item.province}</td>}
                             <td>{item.telephoneNumber}</td>
                             {role <= 2 && <td>{item.numberType == 0 ? "Inteliquent" : "Bandwidth"}</td>}
                             {/* {methodType !== 2 &&<td>{item.rateCenterTier}</td>} */}
                             <td><input  checked={selectedItems.some(tnObj => tnObj.tn === item.telephoneNumber)}
                                    onChange={event => handleCheckboxChange(event, item)} type="checkbox" name="" /></td>
                         </tr>)):(
                            <tr >
                            <td colSpan={6} className='no-result'>No Result found</td>
                           
                        </tr>
                         )}
                           
                        </tbody>
                    </table>
                </div>
            </div>}
            {reserveNumberList.length > 0 && <div className="col-6">
                <div className="common-white-shadow-background setting-section haft-whitebox">
                    <div className='order-queue'>
                    <h3>Order Queue
                    <span><img src={`${process.env.PUBLIC_URL}/images/view.png`} onClick={toggleOrderVisible} style={{ cursor: 'pointer' }} /></span>
                        {/* <span onClick={toggleOrderVisible} style={{ cursor: 'pointer' }}>
                            {orderInstrunction ? '👁️' : '👁️'}
                        </span> */}
                    </h3>
                    <h3>Tel Numbers: {reserveNumberList.length}</h3>
                    </div>
                    {orderInstrunction && (
                        <>
                            <div className="introducebox">
                                <p><strong>Instructions</strong></p>
                                <p>Use 'X' icons to remove Tel Numbers from this table, if you don't want to Order them. <br />
                                    To Order the Numbers in this table: Click the "Next" button.</p>
                                    {/* <p>Reserved TN(s) will remain in the Order Queue for 4 hours. If an order is not generated for the reserved TN(s) within 4 hours, the number(s) will be returned to an Available status and no longer available on your account.</p> */}
                            </div>
                        </>
                    )}
                        
                    <div className="newrow-section footer-row">
                        <a className="delete" onClick={handleRemoveAll} href="#">Remove All</a>
                        <Link className="Cancel" to={"/number-management"}>Cancel</Link>
                        <a href="#" onClick={handleNext} className="next">Next</a>
                    </div>
                    <table className="tablebox create-order-table">
                        <thead>
                            <tr>
                                {methodType !== 2 &&<th>Lata</th>}
                                {methodType !== 2 &&<th>Rate Center</th>}
                                {methodType !== 2 &&<th>State</th>}
                                <th>Tel Number</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                        {reserveNumberList.map((item, index) => 
                             <tr key={index}>
                             {methodType !== 2 &&<td>{item.lata}</td>}
                             {methodType !== 2 &&<td>{item.rateCenterName}</td>}
                             {methodType !== 2 &&<td>{item.province}</td>}
                             <td>{item.telephoneNumber}</td>
                             
                             <td><img src="images/bx-x-circle.png" onClick={event => handleRemoveSelected(event, item)} alt="" /></td>
                         </tr>)}
                            
                        </tbody>
                    </table>
                </div>
            </div>}
        </div>
        {showValidationError && <div className="error">Please add at least one reserve number.</div>}
        {/* <div className="row footer-row">
            <a href="#" onClick={onNext} className="Cancel">Cancel</a>
            <a href="#" onClick={handleNext} className="next">Next</a>
        </div> */}
    </>)
};

export default Step1;